.news-preview {
  padding: 70px 0 85px;

  @include media(1440px) {
    padding: 100px 0 70px;
  }
    
  @include media(1024px) {
    padding: 30px 0;
  }
    
  @include media(600px) {
    padding: 35px 0;
  }

  &__img {
    margin-right: 50px;
    margin-bottom: 10px;
    width: 47.5%;
    flex-shrink: 0;
    align-self: baseline;

    @include media(1440px) {
      margin-right: 58px;
      width: 37.5%;
    }
      
    @include media(768px) {
      display: none;
    }
  }

  &__subtitle {
    display: inline-block;
    max-width: 500px;
  }

  &__unit {
    margin-top: 20px;

    &:nth-child(3n) {
      @include media(1440px) {
        display: none;
      }
      
      @include media(768px) {
        display: block;
      }
    }
  }

  &__link {
    display: block;
    font-size: 30px;
    font-weight: 400;
    line-height: 39px;
    color: $button_color_first;
    text-decoration: underline;
    transition: all 0.5s;

    @include media(1440px) {
      font-size: 28px;
      line-height: 38px;
    }

    @include media(1024px) {
      font-size: 22px;
      line-height: 30px;
    }

    @include media(768px) {
      font-size: 16px;
      line-height: 24px;
    }

    &:hover {
      color: $button_color_first_hover;
      text-decoration: underline;
    }
  }

  &__text {
    display: block;
    margin: 5px 0 10px;
    letter-spacing: -0.04em;
    color: $text_second_color;

    @include media(1024px) {
      line-height: 28px;
    }

    @include media(768px) {
      line-height: 23px;
      letter-spacing: normal;
    }

    @include media(450px) {
      line-height: 20px;
    }
  }

  &__date {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: #afafaf;

    @include media(1024px) {
      font-size: 16px;
      line-height: 26px;
    }

    @include media(768px) {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: normal;
    }
  }
}