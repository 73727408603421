.slider {
  margin: auto;
  max-width: $content_limit;
  color: #ffffff;

  &__unit {
    padding: 70px 50px;
    min-height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media(1440px) {
      padding: 75px 0;
    }

    @include media(1024px) {
      padding: 45px 11px;
      min-height: 345px;
    }

    @include media(768px) {
      background-position: 10% center;
    }

    @include media(600px) {
      background-position: 15% center;
    }

    @include media(450px) {
      padding: 20px 0;
      min-height: 240px;
      background-position: 25% center;
    }
  }

  &__title {
    margin-bottom: 35px;
    font-size: 68px;
    font-weight: 500;
    line-height: 24px;

    @include media(1024px) {
      margin-bottom: 30px;
      font-size: 36px;
      line-height: 42px;
    }

    @include media(450px) {
      margin-bottom: 5px;
      padding: 0 10px;
      font-size: 28px;
      line-height: 34px;
    }
  }

  &__text {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 400;
    line-height: 48px;

    @include media(1024px) {
      margin-bottom: 30px;
      font-size: 26px;
      line-height: 36px;
    }

    @include media(450px) {
      margin-bottom: 20px;
      padding: 0 10px;
      font-size: 20px;
      line-height: 30px;
    }
  }
}
