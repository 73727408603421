.services {
  overflow: hidden;
  padding: 70px 0 50px;

  @include media(1440px) {
    padding: 100px 0 50px;
  }

  @include media(1024px) {
    padding: 40px 0 57px;
  }

  @include media(600px) {
    padding: 20px 0;
  }

  &__header {
    margin-bottom: 60px;

    @include media(1440px) {
      margin-bottom: 50px;
    }

    @include media(1024px) {
      margin-bottom: 40px;
    }

    @include media(600px) {
      margin-bottom: 30px;
    }
  }

  &__button {
    margin-top: 40px;

    @include media(1440px) {
      margin-top: 25px;
    }

    @include media(1024px) {
      margin-top: 15px;
    }

    @include media(600px) {
      display: none;
    }
  }

  &__wrapper {
    flex-wrap: wrap;

    @include media(1024px) {
      flex-direction: column;
    }
  }

  &__unit {
    width: 48.5%;

    @include media(1024px) {
      width: 100%;
    }

    @include media(600px) {
      flex-direction: column;
    }

    &:nth-child(n+3) {
      margin-top: 85px;

      @include media(1440px) {
        margin-top: 125px;
      }
    }

    &:not(:first-child) {
      @include media(1024px) {
        margin-top: 67px;
      }

      @include media(600px) {
        margin-top: 45px;
      }
    }
  }

  &__foto-link {
    align-self: baseline;
    margin-right: 100px;

    @include media(1440px) {
      margin-right: 55px;
    }

    @include media(1024px) {
      margin-right: 50px;
    }

    @include media(600px) {
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  &__view {
    position: relative;
  }

  &__img {
    width: 300px;

    @include media(1440px) {
      width: 222px;
    }

    @include media(1024px) {
      width: 205px;
    }

    @include media(600px) {
      width: 100%;
    }
  }

  &__sub-img {
    position: absolute;
    top: 134px;
    left: 160px;
    width: 183px;

    @include media(1440px) {
      top: 99px;
      left: 118px;
      width: 136px;
    }

    @include media(1024px) {
      top: 91px;
      left: 109px;
      width: 125px;
    }

    @include media(600px) {
      display: none!important;
    }
  }

  &__info {
    position: relative;
  }

  &__title {
    margin-bottom: 20px;
    display: block;
    font-size: 34px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: -0.04em;
    color: $text_main_color;

    @include media(1440px) {
      margin-bottom: 15px;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: normal;
    }

    @include media(1024px) {
      font-size: 24px;
    }

    @include media(768px) {
      font-size: 20px;
      line-height: 38px;
    }

    &:hover {
      color: $main_color;
    }
  }

  &__text {
    margin-bottom: 30px;
    display: block;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.04em;
    color: $text_main_color;

    @include media(1024px) {
      font-size: 18px;
      line-height: 26px;
    }

    @include media(768px) {
      font-size: 16px;
      line-height: 21px;
      letter-spacing: normal;
    }

    @include media(450px) {
      font-size: 14px;
      line-height: 20px;
    }

    ul {
      list-style-type: disc;
      padding-left: 20px;
    }
  }

  &__link {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: $button_color_first;
    text-decoration: underline;
    transition: all 0.5s;

    @include media(1024px) {
      font-size: 18px;
    }

    @include media(768px) {
      font-size: 16px;
    }

    &:hover {
      color: $button_color_first_hover;
      text-decoration: underline;
    }
  }
}

@include media(600px) {
  .page .services__text {
    margin-bottom: 0;
  }

  .page .services__link {
    display: none;
  }
}