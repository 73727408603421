.advantages {
  padding: 20px 0 50px;

  @include media(1440px) {
    padding: 50px 0 25px;
  }

  @include media(1024px) {
    padding: 40px 0 20px;
  }

  &__wrapper {
    @include media(1024px) {
      flex-wrap: wrap;
    }
  }

  &__unit {
    width: 210px;
    padding: 30px 10px 0;

    &:nth-child(n+4) {
      @include media(1024px) {
        margin-top: 45px;
      }
    }
    
    @include media(1024px) {
      width: 26%;
      min-width: 175px;
    }
    
    @include media(600px) {
      width: 100%;
    }
    
    @include media(600px) {
      min-width: 140px;
    }
  }

  &__icon {
    position: relative;
    margin: 0 auto 40px;
    width: 125px;
    height: 125px;
    box-shadow: 0px 15px 20px rgba(171, 171, 171, 0.08);
    border-radius: 50%;
    background-color: #fff;

    @include media(1024px) {
      margin: 0 auto 30px;
    }

    @include media(768px) {
      width: 110px;
      height: 110px;
    }

    @include media(450px) {
      margin: 0 auto 20px;
      width: 90px;
      height: 90px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 50%;
      box-shadow: 0 0 50px 0 rgba(0, 178, 255, 0.5);
      transition: all .5s;

      @include media(600px) {
        box-shadow: 0 0 50px 0 rgba(0, 178, 255, 0.5);
      }
    }

    &:hover:before {
      opacity: 1;
    }
  }

  &__text {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    color: $text_main_color;
    text-align: center;

    @include media(768px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__img {
    @include media(600px) {
      width: auto!important;
    }
  }

  & .owl-nav {
    @include media(600px) {
      top: 44%;
    }
  }

  & .owl-next,
  & .owl-prev {
    @include media(600px) {
      width: 20px;
      height: 30px;
      margin-top: -15px;
      background: 0 0!important;
    }
  }

  & .owl-next:before {
    @include media(600px) {
      left: auto;
      right: 12px;
      border-color: #d0d0d0;
    }
  }

  & .owl-prev:before {
    @include media(600px) {
      left: 0;
      border-color: #d0d0d0;
    }
  }
}

.slider + .advantages {
  margin-top: 50px;

  @include media(600px) {
    margin-top: 0;
  }
}