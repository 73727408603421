.navigation {
    background-color: $main_color;

    &__wrapper {
        height: 50px;

        @include media(1024px) {
            height: 60px;
        }

        @include media(450px) {
            height: 52px;
        }
    }

    &__menu {
        flex-grow: 1;
        margin-right: 2.5%;

        @include media(1024px) {
            display: none;
            margin-right: 0;
        }
    }

    &__list {
        height: 100%;
        display: flex;
        align-items: center;

        &.mm-listview {
            display: block;
        }
    }

    &__sublist {
        position: absolute;
        left: -9999px;
        width: 270px;
        padding: 16px 22px 22px;
        background-color: $main_color;
        opacity: 0;
        transition: opacity 0.5s;
        z-index: 5;

        &.mm-listview {
            position: relative;
            left: 0;
            width: 100%;
            padding: 0;
            opacity: 1;
        }
    }

    &__item {
        margin-right: 5%;

        &.first {
            margin-left: 0;
        }

        &.active > .navigation__link {
            color: $button_color_first;
        }

        &_lvl_1 {
            position: relative;

            &:hover .navigation__sublist {
                left: 0;
                opacity: 1;
            }

            &.mm-listitem {
                margin-left: 0;
            }

            @include media(1440px) {
                margin-left: 3.5%;
            }

            @include media(1200px) {
                margin-left: 3%;
            }
        }

        &_lvl_2 {
            margin-bottom: 14px;

            &.last {
                margin-bottom: 0;
            }

            &.mm-listitem {
                margin-bottom: 0;
            }
        }
    }
    
    &__link {
        font-size: 20px;
        line-height: 24px;
        color: #fff;
        transition: color 0.5s;

        @include media(1440px) {
            font-size: 17px;
            line-height: 22px;
        }

        @include media(1200px) {
            font-size: 16px;
        }

        &:hover {
            color: $button_color_first;
        }
    }

    &__m-logo {
        display: none;
        width: 178px;
        height: 41px;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        background-image: url(../img/logo-white.png);

        @include media(1024px) {
            display: block;
        }

        @include media(700px) {
            width: 150px;
            height: 35px;
        }

        @include media(450px) {
            width: 190px;
            height: 34px;
        }
    }

    &__m-phone {
        display: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #fff;

        @include media(1024px) {
            display: block;
        }
        
        @include media(700px) {
            width: 35px;
            height: 35px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            background-image: url(../img/svg/phone_footer.svg);
            text-indent: -9999px;
        }

        @include media(600px) {
            margin-right: 20px;
            margin-left: auto;
        }

        @include media(450px) {
            width: 26px;
            height: 26px;
        }
    }

    &__button {
        width: 220px;
        height: 100%;
        padding: 0;
        justify-content: center;
        box-shadow: none;
        font-size: 16px;
        line-height: 19px;

        @include media(1440px) {
            width: 188px;
        }

        @include media(1024px) {
            width: 194px;
            height: 40px;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
        }

        @include media(600px) {
            display: none;
        }
    }

    &__hamburger {
        display: none;
        width: 50px;
        height: 35px;
        justify-content: center;
        align-items: center;
        position: relative;

        @include media(1024px) {
            display: flex;
        }

        @include media(450px) {
            width: 36px;
            height: 25px;
        }

        &-add {
            width: 100%;
            height: 5px;
            background-color: #fff;
            border-radius: 3px;
            transition: background-color 0.3s;

            @include media(450px) {
                height: 3px;
            }

            &:before,
            &:after {
                content: '';
                width: 100%;
                height: 5px;
                position: absolute;
                background-color: #fff;
                border-radius: 3px;
                left: 0;
                transition: all 0.5s;

                @include media(450px) {
                    height: 3px;
                }
            }

            &:before {
                top: 0;
            }

            &:after {
                bottom: 0;
            }
        }

        &_active {
            .navigation__hamburger-add {
                background-color: transparent;

                &:before {
                    transform: rotate(45deg);
                    top: 14px;
                }

                &:after {
                    transform: rotate(-45deg);
                    bottom: 15px;
                }

                @include media(450px) {
                    &:before {
                        top: 11px;
                    }

                    &::after {
                        bottom: 11px;
                    }
                }
            }
        }
    }
}

.header {
    &__company-info {
        height: 146px;
        align-items: center;

        @include media(1440px) {
            height: 130px;
            padding: 30px 0;
            align-items: flex-start;
        }

        @include media(1024px) {
            height: 83px;
            padding: 20px 0;
            align-items: center;
        }

        @include media(700px) {
            display: none;
        }
    }

    &__logo {
        width: 145px;
        height: 89px;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        background-image: url(../img/logo.png);

        @include media(1440px) {
            width: 105px;
            height: 65px;
        }

        @include media(1024px) {
            display: none;
        }
    }

    &__infoblock {
        flex-shrink: 0;

        &_address {
            max-width: 320px;

            @include media(1440px) {
                max-width: 310px;
            }

            @include media(1024px) {
                max-width: 290px;
            }
        }
    }

    &__svg {
        margin-top: 4px;
        margin-right: 16px;
        width: 20px;
        height: 20px;
        fill: transparent;
        stroke: $button_color_first;

        &:last-of-type {
            width: 31px;
        }

        @include media(1024px) {
            margin-top: 0;
            margin-right: 10px;
        }
    }

    &__text {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        color: $text_second_color;

        @include media(1024px) {
            font-size: 14px;
            line-height: 20px;
        }

        &_bold {
            font-weight: 600;
        }
    }

    &__social {
        fill: #bdbdbd;
    }

    &__phone {
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
        color: #3d3d3d;

        &:hover {
            color: #3d3d3d;
        }

        @include media(1200px) {
            font-size: 20px;
        }

        @include media(1024px) {
            display: none;
        }
    }

    &__link {
        font-size: 16px;
        font-weight: 300;
        line-height: 19px;
        color: $button_color_first;
        border-bottom: 1px solid $button_color_first;

        &:hover {
            color: $button_color_first;
            border-bottom-color: $button_color_first;
        }

        @include media(1200px) {
            font-size: 14px;
            line-height: 17px;
        }
    }
}