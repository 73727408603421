.certificates {
	padding: 120px 0;
	padding-top: 0 !important;

	@include media(1440px) {
		padding: 100px 0 50px;
	}

	@include media(1024px) {
		padding: 40px 0 57px;
	}

	@include media(600px) {
		padding: 20px 0;
	}

	.owl-carousel {
		padding: 0 40px;

		@include media(1024px) {
			padding: 0;
		}

		.owl-stage {
			display: flex;
		}

		.owl-nav {
			position: absolute;
			left: 0;
			top: 40%;
			z-index: 2;
			width: 100%;
			pointer-events: none;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include media(1024px) {
				display: none;
			}

			button {
				font-size: 40px;
				pointer-events: all;
			}
		}
	}

	&__header {
		margin-bottom: 60px;

		@include media(1024px) {
			margin-bottom: 40px;
		}

		@include media(600px) {
			margin-bottom: 30px;
		}
	}

	&__button {
		margin-top: 40px;

		@include media(1440px) {
			margin-top: 25px;
		}

		@include media(1024px) {
			margin-top: 15px;
		}

		@include media(600px) {
			display: none;
		}
	}

	&__wrapper {
		flex-wrap: wrap;

		@include media(1024px) {
			flex-direction: column;
		}
	}

	&__title {
		margin-bottom: 35px;
		display: block;
		font-size: 34px;
		font-weight: 700;
		line-height: 41px;
		letter-spacing: -0.04em;
		color: $main_color;

		@include media(1440px) {
			margin-bottom: 20px;
			font-size: 30px;
			line-height: 33px;
			letter-spacing: normal;
		}

		@include media(1024px) {
			font-size: 24px;
		}

		@include media(768px) {
			font-size: 20px;
			line-height: 38px;
		}

		&:hover {
			color: $main_color;
		}
	}

	&__text {
		margin-bottom: 40px;
		display: block;
		font-size: 24px;
		font-weight: 300;
		line-height: 38px;
		letter-spacing: -0.04em;
		color: $text_main_color;

		@include media(1024px) {
			font-size: 18px;
			line-height: 26px;
		}

		@include media(768px) {
			font-size: 16px;
			line-height: 21px;
			letter-spacing: normal;
		}

		@include media(450px) {
			font-size: 14px;
			line-height: 20px;
		}
	}

}

.certificate {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 15px solid #F9FBFE;
	position: relative;
	height: 100%;

	&::before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.24);
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		opacity: 0;
		transition: opacity 0.3s;
	}

	&::after {
		content: "";
		display: block;
		width: 54px;
		height: 54px;
		background-color: rgba(255, 255, 255, 0.32);
		background-image: url('../img/svg/ico_zoom.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 20px;
		border-radius: 50%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		opacity: 0;
		transition: opacity 0.3s;
	}

	&:hover {
		&::before,
		&::after {
			opacity: 1;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}
}

@include media(600px) {
	.page .services__text {
		margin-bottom: 0;
	}

	.page .services__link {
		display: none;
	}
}