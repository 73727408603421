.price {
    &__header {
        margin-bottom: 47px;
        padding-bottom: 23px;
        padding-right: 30px;
        position: relative;
        border-bottom: 3px solid rgba(56,131,232,0.2);
        cursor: pointer;

        @include media(1440px) {
            margin-bottom: 51px;
            padding-bottom: 21px;
        }

        @include media(1024px) {
            margin-bottom: 30px;
            padding-bottom: 20px;
        }

        @include media(600px) {
            margin-bottom: 18px;
            padding-bottom: 11px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 30px;
            height: 3px;
            background-color: $main_color;
        }

        &::after {
            content: '';
            position: absolute;
            top: 18px;
            right: 3px;
            width: 18px;
            height: 18px;
            border-bottom: 4px solid $main_color;
            border-left: 4px solid $main_color;
            transform: rotate(-45deg);

            @include media(1440px) {
                top: 13px;
            }

            @include media(1024px) {
                top: 5px;
            }

            @include media(600px) {
                top: 5px;
                width: 8px;
                height: 8px;
                border-width: 2px;
            }
        }

        &_active {
            &::after {
                top: 14px;
                border: none;
                border-top: 4px solid #bdbdbd;
                border-right: 4px solid #bdbdbd;

                @include media(1440px) {
                    top: 11px;
                }
    
                @include media(1024px) {
                    top: 7px;
                }
    
                @include media(600px) {
                    top: 5px;
                    border-width: 2px;
                }
            }

            & .price__table {
                display: block;
            }
        }
    }

    &__title {
        font-size: 40px;
        font-weight: 400;
        line-height: 52px;
        color: $main_color;
        text-transform: uppercase;

        @include media(1440px) {
            font-size: 30px;
            line-height: 42px;
        }

        @include media(1024px) {
            font-size: 22px;
            line-height: 31px;
        }

        @include media(600px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    &__masters {
        display: block;
        font-size: 26px;
        font-weight: 300;
        line-height: 34px;
        letter-spacing: -0.04em;

        @include media(1440px) {
            font-size: 24px;
            line-height: 34px;
        }

        @include media(1024px) {
            font-size: 18px;
            line-height: 23px;
            letter-spacing: normal;
        }

        @include media(600px) {
            font-size: 14px;
            line-height: 20px;
        }

        &_name {
            font-weight: 400;
        }
    }

    &__table {
        margin-bottom: 70px;
        display: none;

        @include media(1024px) {
            margin-bottom: 30px;
        }
    }

    &__row {
        padding: 8px 30px;
        min-height: 65px;

        @include media(1024px) {
            min-height: 50px;
        }

        @include media(600px) {
            padding: 10px;
            min-height: 36px;
        }

        &:nth-child(2n+1) {
            background-color: #fafafa;
        }

        &_header {
            background-color: $main_color!important;
            font-size: 24px;
            font-weight: 500;
            line-height: 52px;
            text-transform: uppercase;
            text-decoration: none;
            color: #fff;

            @include media(1440px) {
                line-height: 42px;
            }

            @include media(1024px) {
                font-size: 20px;
                line-height: 30px;
            }

            @include media(600px) {
                font-size: 16px;
                line-height: 20px;
            }
          
            @include media(450px) {
              font-size: 14px;
            }
        }
    }

    &__name {
        flex-basis: 53%;
        flex-shrink: 0;

        @include media(450px) {
            flex-basis: 40%;
        }
        
        &_item {
            font-size: 20px;
            font-weight: 300;
            line-height: 65px;

            @include media(1024px) {
                font-size: 50px;
            }

            @include media(600px) {
                font-size: 12px;
                line-height: 36px;
            }
        }
    }

    &__cost {
        flex-basis: 24%;
        flex-shrink: 0;
        text-align: center;

        @include media(450px) {
            flex-basis: 35%;
        }
        
        &_item {
            font-size: 26px;
            font-weight: 300;
            line-height: 22px;

            @include media(1024px) {
                font-size: 20px;
            }

            @include media(600px) {
                font-size: 12px;
                line-height: 16px;
            }
        }

        &_bold {
            font-weight: 400;
        }
    }

    &__order {
        flex-basis: 21%;
        flex-shrink: 0;
        text-align: right;

        @include media(450px) {
            flex-basis: 25%;
        }
        
        &_item {
            font-size: 20px;
            font-weight: 300;
            line-height: 22px;
            color: $button_color_first;
            text-decoration: underline;

            @include media(1440px) {
                font-size: 20px;
            }

            @include media(1024px) {
                font-size: 16px;
            }

            @include media(600px) {
                font-size: 12px;
                line-height: 16px;
            }
            
            & .js_popup_order {
                cursor: pointer;
            }
        }
    }

    &_options  {
        & .price__name {
            font-size: 26px;
            font-weight: 300;

            @include media(1440px) {
                font-size: 20px;
            }

            @include media(1024px) {
                font-size: 18px;
            }

            @include media(600px) {
                font-size: 12px;
            }
        }

        & .price__cost {
            font-size: 26px;
            font-weight: 400;
            text-align: right;

            @include media(1024px) {
                font-size: 22px;
            }

            @include media(600px) {
                font-size: 12px;
            }
        }
    }
}