.social_links {

  .svg_icon {
      width: 38px;
      height: 30px;
      fill: rgba(#fff, 0.6);
      display: block;
      @include media(1200px) {
          width: 30px;
          height: 25px;
      }

      transition: all 0.3s;

    &:hover {
      fill: #fff;
    }
  }
  .fb .svg_icon {
      height: 38px;
      width: 34px;
      @include media(1200px) {
          height: 30px;
          width: 28px;
      }
  }
  .vk .svg_icon {
      height: 34px;
      @include media(1200px) {
          height: 28px;
      }
  }
  .social_link {
      margin-right: 25px;
      @include media(1200px) {
          margin-right: 15px;
      }
      &:last-child {
          margin-right: 0;
      }
  }
}

.page_contacts .social_links {
  display: flex;
}

.main_contacts{
  padding: 60px 0;
  position: relative;
  background-color: $main_color;
  margin: 0;
    overflow: hidden;
}

.main_contacts h2, .contacts_title{
  color: #fff;
}

.main_contacts .left_contacts {
  .contacts_title {
    display: none;
  }

  .contacts_phone {
    margin-top: 0;

    @include media(600px) {
      margin-top: 20px;
    }
  }
}

.contacts_title {
  font-size: 38px;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: -0.04em;
  text-transform: uppercase;

  @include media(1440px) {
      font-size: 30px;
      line-height: 56px;
  }

  @include media(1024px) {
      font-size: 22px;
      line-height: 20px;
  }

  @include media(600px) {
      font-size: 20px;
      line-height: 28px;
  }
}

.left_contacts, .right_contacts{
  float: left;
  box-sizing: border-box;
}

.left_contacts{
  width: 37%;
  padding-right: 8px;
  border-right: 1px solid rgba(255,255,255,0.35);

  .social_links {
      @include media(600px) {
          width: 100%;
          padding-top: 20px;
      }
  }
}

.right_contacts{
  width: 63%;
}

.footer_contacts_title{
  display: block;
  font-size: 18px;
  font-weight: 300;
}

.footer_contacts_block, .footer_contacts_block a{
  color: #ffffff;
  text-decoration: none;
  font-size: 24px;
  font-weight: 500;
}

.footer_contacts_block{
  margin-bottom: 25px;
  padding-left: 50px;
  position: relative;
}

.footer_contacts_block:before{
  content: '';
  position: absolute;
  width: 32px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  top: 0;
  background-size: contain;
}

.contacts_phone{
  margin-top: 35px;
}

.contacts_phone:before{
  background-image: url(../img/svg/phone_footer.svg);
}

.contacts_mail:before{
  background-image: url(../img/svg/mail_footer.svg);
}

.contacts_address:before{
  background-image: url(../img/svg/map_footer.svg);
  background-size: 75%;
}

.contacts_time:before{
  background-image: url(../img/svg/clock_footer.svg);
}

.right_contacts_desc span{
  font-weight: 600;
}

.right_contacts_desc{
  font-size: 30px;
  font-weight: 300;
  color: #fff;
  margin: 20px 0;
  margin-bottom: 40px;
}

.right_contacts {
  padding-left: 100px;
  .checkbox_block {
      margin-bottom: 0;
  }
  .checkbox_text {
      color: #fff;
      &:before {
          color: #fff;
          border-color: #fff;
      }
      a {
          color: #fff;
      }
  }
}

.right_contacts_form {
  flex-wrap: wrap;
}

.right_contacts_form .input_block input{
  height: 40px;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(255,255,255,0.35);
  color: #fff;
  font-weight: 300;
    border-radius: 0;
    padding-left: 0;
}

.right_contacts_form .input_block input::placeholder{
  font-size: 20px;
  color: #fff;
}

.right_contacts_form .input_block {
  width: 47%;
  margin-right: 6%;
  margin-bottom: 55px;
}

.right_contacts_form .input_block:nth-child(2n){
  margin-right: 0;
}

.right_contacts_form .checkbox_block {
  width: 47%;
  order: 2;

  @include media(1200px) {
      width: 100%;
      margin-bottom: 40px;
      order: 0;
  }

  @include media(600px) {
      margin-bottom: 25px;
  }
}

.right_contacts_form .button {
  @include media(1440px) {
      width: 47%;
  }

  @include media(1200px) {
      width: 293px;
  }

  @include media(600px) {
      margin-bottom: 40px;
  }

  @include media(450px) {
      width: 100%;
  }
}

.checkbox_text {
  position: relative;
  line-height: 30px;
  vertical-align: middle;
  z-index: 5;
  font-weight: 300;
  color: #565656;
  margin: 0;
  &:before {
      content: " ";
      color: #565656;
      display: inline-block;
      margin-right: 14px;
      position: relative;
      text-align: center;
      text-indent: 0px;
      width: 20px;
      height: 20px;
      background: inherit;
      border: 1px solid #565656;
      border-image: initial;
      vertical-align: middle;
      margin-bottom: 2px;
  }
  a {
      color: #565656;
      text-decoration: none;
      font-weight: 500;
  }
}
.conf_checkbox:checked + .checkbox_text:after {
    content: "";
    display: block;
    width: 20px;
    height: 13px;
    position: absolute;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    top: 4px;
    left: 2px;
    transform: rotate(-45deg);

  @include media(768px) {
      line-height: 15px;
      width: 15px;
      height: 10px;
      top: 7px;
      left: 2px;
  }
}

.popup .conf_checkbox:checked + .checkbox_text:after {
    border-color: #565656;
}

.conf_checkbox:disabled + .checkbox_text:before {
  background: inherit;
  color: #aaa;
}

.checkbox_block{
  position: relative;
  text-align: left;
}

input.conf_checkbox {
  position: absolute;
  top: 15px;
  left: 5px;
  width: 2px;
  height: 2px;
  margin: 0;
  opacity: 0;
}

.contacts_page h1{
  margin-bottom: 60px;
}

.maps_block{
  margin-top: 50px;
}

.get_to_us{
  margin-top: 30px;
  margin-bottom: 40px;
}

.get_to_us span{
  text-decoration: underline;
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  padding: 0 25px;
}

.get_to_us .get_to_auto{
  margin-right: 20px;
}

.get_to_us span.active{
  background-color: #3883e8;
  color: #fff;
  -webkit-box-shadow: 1px 4px 18px rgba(109, 109, 109, 0.17);
  -moz-box-shadow: 1px 4px 18px rgba(109, 109, 109, 0.17);
  box-shadow: 1px 4px 18px rgba(109, 109, 109, 0.17);
  text-decoration: none;
}


.get_to_block{
  margin-top: 40px;
  position: relative;
  display: none;
  width: 70%;
  display: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.get_to_block.active{
  display: block;
}

.get_to_block li{
  position: relative;
}

.contacts_page ul.get_to_block li:before{
  content: '';
  position: absolute;
  background-color: transparent;
  background-image: url(../img/yes.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 0;
}

.contacts_page .page__wrapper_padding {
  padding-bottom: 0;
}

.yandex_maps {
  height: 620px;

  @include media(1024px) {
    height: 400px;
  }

  @include media(600px) {
    height: 302px;
  }
}

.ymBlock{
  width: 100% !important;
}

.ymaps-2-1-72-image{
  width: 40px !important;
  height: 66px !important;
  background-size: 40px 66px !important;
  top: -60px !important;
}

.content_contact{
  margin: 50px 0 40px 0;
}

.content_contact h3{
  margin-bottom: 30px;
}



/* media */


@media (max-width: 1460px) {
  .right_contacts_form .checkbox_text{
      display: block;
      width: 100%;
  }
}

@media (max-width: 1300px) {
  
  .right_contacts_desc {
      font-size: 26px;
  }
  
  .contacts_title{
      font-size: 36px;
  }
}


@media (max-width: 1000px){
  .contacts_title{
      font-size: 24px;
  }
  
  .right_contacts_desc {
      font-size: 20px;
  }
}

@media (max-width: 768px){    
  .get_to_block{
      width: 100%;
  }
  
  .right_contacts{
      padding-left: 30px;
  }
  
  .right_contacts_form .input_block{
      width: 330px;
      max-width: 90%;
      margin: 0 0 20px 0;
  }
  
  .right_contacts_form .input_block input{
      margin-bottom: 0;
  }
  
  .right_contacts_desc{
      font-size: 14px;
      margin-bottom: 20px;
  }
  
  .footer_contacts_title{
      font-size: 14px;
  }
  
  .footer_contacts_block, .footer_contacts_block a{
      font-size: 18px;
  }
  
  .contacts_social a{
      background-size: contain;
      width: 30px;
      height: 30px;
  }
  
  .right_contacts_form .checkbox_text{
      font-size: 12px;
      margin: 0 0 8px 0;
  }
  
  label.checkbox_text:before{
      width: 16px;
      height: 16px;
      font: 14px/16px Arial;
      margin-right: 10px;
  }
  
  .main_contacts:before{
      width: 41%;
      height: 37%;
  }
  
  .main_contacts:after{
      width: 50%;
      height: 40%;
  }
  
  .get_to_block:before{
      top: 3px;
  }
}

@media (max-width: 600px){
  .contacts_title {
      font-size: 20px;
  }
  
  .left_contacts, .right_contacts{
      float: none;
      width: 100%;
  }
  
  .main_contacts{
      display: table;
      padding: 20px 0;
  }
  
  .left_contacts{
      display: table-footer-group;
      line-height: 1.5;
  }
  
  .right_contacts{
      display: table-header-group;
      line-height: 1.5;
  }

  .right_contacts_form .input_block {
      margin-bottom: 15px;
  }
  
  .right_contacts_desc{
      margin-top: 10px;
      font-size: 12px;
      line-height: 14px;
  }
  
  .right_contacts_form .input_block{
      width: 100%;
      max-width: 100%;
  }
  
  .right_contacts_form .input_block input{
      font-size: 12px;
      height: 20px;
      &::placeholder {
          font-size: 12px;
      }
  }
  
  .footer_contacts_block{
      width: 47%;
      float: left;
      box-sizing: border-box;
      margin: 20px 6% 0 0;
      padding-left: 34px;
  }
  
  .footer_contacts_block:nth-child(2n+1){
      margin-right: 0;
  }
  
  .footer_contacts_title, .footer_contacts_block, .footer_contacts_block a{
      font-size: 12px;
  }
  
  .footer_contacts_block:before {
    top: 2px;
    width: 22px;
    height: 20px;
    background-size: contain;
  }
  
  .left_contacts .contacts_social{
      clear: both;
      padding-top: 18px;
  }
  
  .contacts_social a{
      width: 22px;
      height: 22px;
      margin-right: 10px;
  }
  
  .contacts_address:before {
    top: 0;
    height: 31px;
  }

  .contacts_phone:before {
    top: 3px;
  }
}