$main_color: #404040;

$text_main_color: #565656;
$text_second_color: #3f3f3f;

$button_color_first: #16A5CA;
$button_color_first_hover: #4AC1E0;
$button_color_second: #7166f1;
$button_color_second_hover: #524ba8;

$background_color: #F5F5F5;

$content_limit: 1660px;