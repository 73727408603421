.service {
  &__header {
    margin-bottom: 77px;
    flex-wrap: wrap;

    @include media(600px) {
      margin-bottom: 20px;
    }
  }

  &__img {
    width: 44%;
    height: 325px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include media(1440px) {
      width: 46%;
      height: 258px;
    }

    @include media(1028px) {
      margin-bottom: 33px;
      width: 100%;
      height: 265px;
    }
  }

  &__info {
    width: 49%;

    @include media(1028px) {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 40px;
    font-size: 38px;
    font-weight: 500;
    line-height: 46px;

    @include media(1440px) {
      margin-bottom: 20px;
      font-size: 34px;
      line-height: 41px;
      letter-spacing: -0.04em;
    }

    @include media(1024px) {
      font-size: 32px;
      line-height: 38px;
    }

    @include media(768px) {
      font-size: 30px;
      line-height: 36px;
    }

    @include media(600px) {
      margin-bottom: 10px;
      font-size: 22px;
      line-height: 27px;
    }

    @include media(450px) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__text {
    display: block;
    font-size: 28px;
    font-weight: 300;
    line-height: 42px;
    letter-spacing: -0.04em;

    @include media(1440px) {
      font-size: 28px;
      line-height: 42px;
    }

    @include media(600px) {
      font-size: 20px;
      line-height: 32px;
    }

    @include media(450px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__cost {
    font-weight: 500;
  }

  &__link {
    display: block;
    font-size: 24px;
    font-weight: 300;
    line-height: 48px;
    letter-spacing: -0.04em;
    color: $main_color;
    text-decoration: underline;
    cursor: pointer;

    @include media(600px) {
      font-size: 20px;
      line-height: 40px;
    }

    @include media(450px) {
      font-size: 16px;
      line-height: 32px;
    }

    &:hover {
      color: $main_color;
      text-decoration: underline;
    }
  }
}