.button {
  margin-bottom: 0;
  padding: 10px 20px;
  width: 300px;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 5px 8px 15px rgba(0, 0, 0, 0.08);
  border: none;
  border-radius: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: normal;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  transition: all 0.5s;
  z-index: 2;

  @include media(1400px) {
    padding-left: 25px;
  }

  @include media(1024px) {
    padding-left: 15px;
  }

  @include media(768px) {
    width: 242px;
    height: 50px;
    font-size: 14px;
    line-height: 17px;
  }

  @include media(450px) {
    margin: auto;
    padding: 10px 22px 10px 17px;
    justify-content: space-between;
  }

  @include media(350px) {
    width: 100%;
  }

  &:hover {
    color: #fff;
  }

  &:focus {
    color: #fff;
  }

  &_color {
    &_first {
      background-color: $button_color_first;

      &:hover {
        background-color: $button_color_first_hover;
      }
    }

    &_second {
      background-color: $button_color_second;

      &:hover {
        background-color: $button_color_second_hover;
      }
    }
  }

  &_popup {
    margin: 25px auto 0;
  }

  &_review {
    margin-left: auto;
    margin-right: auto;
  }

  &__arrow {
    margin-left: 20px;
    position: relative;
    width: 45px;
    height: 2px;
    background-color: #fff;

    @include media(768px) {
      width: 32px;
      height: 1px;
    }

    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: -5px;
      width: 12px;
      height: 12px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg);

      @include media(768px) {
        top: -3px;
        width: 7px;
        height: 7px;
        border-top-width: 1px;
        border-right-width: 1px;
      }
    }
  }
}

.social {
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media(1200px) {
      width: 100px;
      align-self: center;
  }

  @include media(1024px) {
      order: 1;
  }

  &__svg {
    fill: #bdbdbd;
    transition: all 0.3s;

    &:hover {
      fill: $button_color_first;
    }

    &_inst {
        width: 28px;
        height: 28px;
  
        @include media(1200px) {
            width: 20px;
            height: 20px;
        }
    }
  
    &_fb {
        width: 36px;
        height: 36px;
  
        @include media(1200px) {
            width: 22px;
            height: 22px;
        }
    }
  
    &_vk {
        width: 39px;
        height: 39px;
  
        @include media(1200px) {
            width: 28px;
            height: 28px;
        }
    }
  }
}

.title {
  font-size: 56px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.04em;
  color: $text_main_color;
  text-transform: uppercase;

  @include media(1400px) {
    font-size: 56px;
    line-height: 56px;
  }

  @include media(1024px) {
    font-size: 46px;
    line-height: 46px;
  }

  @include media(768px) {
    font-size: 38px;
    line-height: 36px;
  }

  @include media(600px) {
    font-size: 32px;
  }

  @include media(450px) {
    font-size: 26px;
  }
}

.subtitle {
  font-size: 40px;
  font-weight: 400;
  line-height: 52px;
  color: $text_main_color;
  text-transform: uppercase;

  @include media(1400px) {
    font-size: 30px;
    line-height: 42px;
  }

  @include media(1024px) {
    font-size: 26px;
    line-height: 36px;
  }

  @include media(768px) {
    font-size: 22px;
    line-height: 31px;
  }

  @include media(600px) {
    font-size: 18px;
    line-height: 25px;
  }

  @include media(450px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.smalltitle {
  font-size: 46px;
  font-weight: 500;
  line-height: 55px;
  letter-spacing: -0.04em;
  color: $text_main_color;

  @include media(1440px) {
    font-size: 44px;
    line-height: 53px;
    letter-spacing: normal;
  }

  @include media(1024px) {
    font-size: 38px;
    line-height: 44px;
  }

  @include media(768px) {
    font-size: 32px;
    line-height: 38px;
  }

  @include media(600px) {
    font-size: 26px;
    line-height: 32px;
  }

  @include media(450px) {
    font-size: 22px;
    line-height: 26px;
  }
}

.light-text {
  font-size: 24px;
  font-weight: 200;
  line-height: 38px;

  @include media(1024px) {
    font-size: 20px;
    line-height: 32px;
  }

  @include media(768px) {
    font-size: 18px;
    line-height: 26px;
  }

  @include media(600px) {
    font-size: 16px;
    line-height: 24px;
  }

  @include media(450px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.page {
  padding: 60px 0 100px;

  @include media(1024px) {
    padding: 40px 0 60px;
  }

  @include media(600px) {
    padding: 15px 0 40px;
  }

  &_no-bottom {
    padding-bottom: 0;
  }

  &__title {
    margin-bottom: 35px;
    font-size: 68px;
    font-weight: 500;
    line-height: 82px;
    letter-spacing: -0.04em;
    color: $text_main_color;

    @include media(1440px) {
      font-size: 56px;
      line-height: 67px;
    }

    @include media(1024px) {
      margin-bottom: 25px;
      font-size: 46px;
      line-height: 52px;
    }

    @include media(768px) {
      font-size: 38px;
      line-height: 36px;
      letter-spacing: normal;
    }

    @include media(600px) {
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 34px;
    }

    @include media(450px) {
      font-size: 26px;
      line-height: 31px;
    }

    &_article {
      margin-bottom: 30px;
      font-size: 46px;
      font-weight: 500;
      line-height: 55px;
      letter-spacing: -0.04em;

      @include media(1440px) {
        font-size: 44px;
        line-height: 53px;
      }

      @include media(1024px) {
        font-size: 38px;
        line-height: 42px;
      }

      @include media(768px) {
        font-size: 32px;
        line-height: 38px;
      }

      @include media(600px) {
        font-size: 26px;
        line-height: 32px;
      }

      @include media(450px) {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }

  &__wrapper {
    padding: 50px 80px;

    @include media(1440px) {
      padding: 40px 80px;
    }

    @include media(1024px) {
      padding: 30px 33px;
    }

    @include media(600px) {
      padding: 20px 11px;
    }

    &_container {
      margin: auto;
      max-width: 1580px;

      @include media(1440px) {
        max-width: 1228px;
      }
    }

    &_padding {
      padding-left: 0;
      padding-right: 0;
    }

    &_bg {
      background-color: $background_color;
    }

    &_margin {
      margin: 50px 0;

      @include media(1440px) {
        margin: 40px 0;
      }

      @include media(1024px) {
        margin: 30px 0;
      }

      @include media(600px) {
        margin: 20px 0;
      }
    }
  }

  &__img {
    float: left;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &_about {
      margin-right: 50px;
      margin-bottom: 40px;
      width: 750px;
      height: 625px;

      @include media(1440px) {
        margin-right: 45px;
        margin-bottom: 25px;
        width: 370px;
        height: 300px;
      }

      @include media(1024px) {
        margin-bottom: 12px;
      }

      @include media(768px) {
        margin-bottom: 30px;
        margin-right: 0;
        width: 100%;
        height: 400px;
      }
    }
  }

  &__content {
    font-size: 24px;
    font-weight: 300;
    line-height: 38px;
    letter-spacing: -0.04em;
    color: $text_main_color;

    @include media(1024px) {
      font-size: 20px;
      line-height: 28px;
    }

    @include media(768px) {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: normal;
    }

    @include media(600px) {
      font-size: 16px;
    }

    @include media(450px) {
      font-size: 14px;
      line-height: 20px;
    }

    &_flaot {
      display: inline;
    }

    & h2 {
      margin-bottom: 60px;
      font-size: 46px;
      font-weight: 500;
      line-height: 55px;
      letter-spacing: -0.04em;

      @include media(1440px) {
        font-size: 44px;
        line-height: 53px;
        letter-spacing: normal;
      }

      @include media(1024px) {
        margin-bottom: 40px;
        font-size: 38px;
        line-height: 46px;
      }

      @include media(768px) {
        font-size: 32px;
        line-height: 38px;
      }

      @include media(600px) {
        margin-bottom: 20px;
        font-size: 26px;
        line-height: 32px;
      }

      @include media(450px) {
        font-size: 22px;
        line-height: 26px;
      }
    }

    & h3 {
      margin-bottom: 20px;
      font-size: 38px;
      font-weight: 500;
      line-height: 46px;
      letter-spacing: normal;

      @include media(1440px) {
        margin-bottom: 30px;
        font-size: 30px;
        line-height: 42px;
      }
  
      @include media(1024px) {
        margin-bottom: 20px;
        font-size: 26px;
        line-height: 36px;
      }
  
      @include media(768px) {
        font-size: 22px;
        line-height: 31px;
      }
  
      @include media(600px) {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 24px;
      }
  
      @include media(450px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    & p:not(:last-of-type) {
      margin-bottom: 30px;
    }

    &.light-text p {
      font-size: 24px;
      font-weight: 200;
      line-height: 38px;

      @include media(1024px) {
        font-size: 20px;
        line-height: 32px;
      }
    
      @include media(768px) {
        font-size: 18px;
        line-height: 26px;
      }
    
      @include media(600px) {
        font-size: 16px;
        line-height: 24px;
      }
    
      @include media(450px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    & ul {
      list-style-type: none;
    }

    & li {
      margin-bottom: 15px;
      padding-left: 28px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 6px;
        top: 12px;
        width: 7px;
        height: 7px;
        background-color: #3883e8;
        border-radius: 50%;

        @include media(1024px) {
          top: 8px;
        }

        @include media(450px) {
          top: 7px;
        }
      }
    }

    &_colorh3 {
      & h3 {
        color: $main_color;
      }
    }

    &_upperh3 {
      & h3 {
        margin-bottom: 40px;
        font-size: 40px;
        font-weight: 400;
        line-height: 52px;
        letter-spacing: normal;
        text-transform: uppercase;
  
        @include media(1440px) {
          margin-bottom: 30px;
          font-size: 30px;
          line-height: 42px;
        }
    
        @include media(1024px) {
          margin-bottom: 20px;
          font-size: 26px;
          line-height: 36px;
        }
    
        @include media(768px) {
          font-size: 22px;
          line-height: 31px;
        }
    
        @include media(600px) {
          margin-bottom: 10px;
          font-size: 18px;
          line-height: 24px;
        }
    
        @include media(450px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &_columns {
      column-count: 2;
      column-gap: 60px;

      @include media(1024px) {
        column-gap: 20px;
      }

      @include media(600px) {
        column-count: 1;
      }
    }

    &_blocks {
      flex-wrap: wrap;
    }
  }

  &__block {
    width: 48.5%;

    @include media(600px) {
      width: 100%;

      &:not(:first-child) {
        margin-top: 30px;
      }
    }
  }

  &__color-text {
    font-size: 40px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: normal;
    color: $main_color;
    text-align: center;
    text-transform: uppercase;

    @include media(1440px) {
      font-size: 30px;
      line-height: 42px;
    }

    @include media(1024px) {
      font-size: 26px;
      line-height: 36px;
    }

    @include media(768px) {
      font-size: 22px;
      line-height: 31px;
    }

    @include media(600px) {
      font-size: 18px;
      line-height: 26px;
    }

    @include media(450px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.about__margin {
  margin-top: 25px;
  margin-bottom: 100px;

  @include media(1440px) {
    margin-top: 45px;
    margin-bottom: 70px;
  }

  @include media(1024px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @include media(600px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

/* ******************************* OLD ************************************** */
.breadcrumb {
  list-style: none;
  margin: 0;
  line-height: 24px;

  @include media(1024px) {
    line-height: 19px;
  }

  @include media(600px) {
    line-height: 14px;
  }
}

.breadcrumb li {
  display: inline-block;
  color: $button_color_first;
  margin: 0;
  font-size: 20px;

  @include media(1024px) {
    font-size: 16px;
  }

  @include media(600px) {
    font-size: 12px;
  }
}

.breadcrumb li a {
  color: #232222;
  text-decoration: none;
}

.breadcrumb_block {
  padding: 12px 0;
  background-color: #fafafa;

  @include media(1024px) {
    padding: 10px 0;
  }
}

.breadcrumb li:not(:first-child)::before {
  content: "/";
  display: inline-block;
  margin: 0px 12px;
  color: #232222;

  @include media(600px) {
    margin: 0 8px;
  }
}

.mm-navbar, .mm-panel, .mm-panels{
  background: $main_color;
}

.mm-listitem:after{
  left: 0;
}

.mm-btn_next:after{
  right: 50%;
}

@media (max-width: 450px){    
  .mm-btn_next:after{
      margin-right: 5px;
  }
}


/* ************************************************************************************
********************************** пагинцая начало ************************************
************************************************************************************ */
.page_block{
  clear: both;
  text-align: center;
  letter-spacing: normal;
  margin-top: 60px;

  @include media(1440px) {
    margin-top: 50px;
  }

  @include media(1024px) {
    margin-top: 40px;
  }

  @include media(600px) {
    margin-top: 30px;
  }
}

.page_block ul {
  list-style: none;
}

.page_block li {
  display: inline-block;
  color: #9b9b9b;
  font-size: 34px;
  font-weight: 200;
  margin: 0 10px;
}

.page_block li.active {
  font-weight: 400;
  color: $main_color;
}

.page_block li a {
  color: #9b9b9b;
  font-size: 34px;
  text-decoration: none;
  font-weight: 200;
}

.page_prev, .page_next {
  width: 60px;
  height: 22px;
  display: block;
  position: relative;
}

.page_prev {
  margin-right: 36px;
}

.page_next {
  margin-left: 36px;
}

.page_prev:before, .page_prev:after, .page_next:after, .page_next:before {
  position: absolute;
  content: '';
}

.page_prev:before, .page_next:before {
  width: 100%;
  height: 1px;
  top: 10px;
  left: 0;
  background-color: $main_color;
}

.page_prev:after, .page_next:after {
  width: 10px;
  height: 10px;
  border-left: 1px solid $main_color;
  border-top: 1px solid $main_color;
  transform: rotate(-45deg);
  left: 1px;
  top: 5px;
}

.page_next:after {
  border: none;
  border-bottom: 1px solid $main_color;
  border-right: 1px solid $main_color;
  right: 1px;
  left: auto;
}

.page_prev.disabled:after, .page_next.disabled:after {
  border-color: #9b9b9b;
}

.page_prev.disabled:before, .page_next.disabled:before {
  background-color: #9b9b9b;
}

.circle_page li a{
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #cfcfcf;
}

.circle_page li.active{
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $main_color;
}

.circle_page{
  margin-top: 35px;
  margin-bottom: 70px;
}

@include media(600px) {
  .page_block li {
    margin: 0 4px;
  }

  .page_block li a, .page_block li {
    font-size: 24px;
  }

  .page_block{
    margin-top: 20px;
  }
  .page_prev, .page_next {
    width: 40px;
  }
  
  .page_prev:before, .page_next:before {
    top: 14px;
  }
  
  .page_prev:after, .page_next:after {
    top: 10px;
    width: 8px;
    height: 8px;
  }
}
/* ************************************************************************************
********************************** пагинцая конец *************************************
************************************************************************************ */

/* ************************************************************************************
********************************** OWL OVERRIDE ***************************************
************************************************************************************ */
.owl-dots{
  text-align: center;
  margin-top: 35px;
}

.owl-dots button.owl-dot{
  width: 18px;
  height: 18px;
  background: #cfcfcf;
  border-radius:50%;
  margin-right: 13px;
}

.owl-dots button.owl-dot:last-child{
  margin: 0;
}

.owl-dots button.owl-dot.active{
  background: $button_color_first;
}

button.owl-dot:after{
  content: none;
}

@include media(1024px) {
  .owl-next {
    right: 5px;
  }

  .owl-prev {
    left: 5px;
  }

  .owl-next,
  .owl-prev {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.2) !important;
    top: 0;
    margin-top: -30px;
  }

  .owl-next:after,
  .owl-prev:after {
    content: none;
  }

  .owl-next:before,
  .owl-prev:before {
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg) translateY(-50%);
    top: 45%;
    left: 10px;
  }

  .owl-prev:before {
    border: 0;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    left: 18px;
  }

  .owl-nav {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
  }
}

@include media(450px) {
  .owl-dots {
    margin-top: 30px;
  }

  .owl-dots button.owl-dot {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}
/* ************************************************************************************
*************************************** OWL END ***************************************
************************************************************************************ */

.mm-panel:after, .mm-panel:before {
    display: none;
}
.mm-panel {
    padding-top: 64px;
}
.mm-listitem__btn {
    padding-right: 47px;
}
.mm-btn_next:after {
    right: 35%;
}

.surname_valid {
    position: fixed !important;
    top: -1000% !important;
    left: -1000% !important;
}
